/****** css for inputwithicon input begins ********/

.input-icon-wrapper {
    display: flex;
    align-items: center;
    background-color: #f0f1f4;
    border-radius: 5px;
}

.k-form .k-form-field .input-icon-wrapper input {
    border: none;
    box-shadow: none;
    background-color: transparent;
    color: black;
}

.k-textbox {
    height: 32px;
    width: 100%;
}

.k-form-field {
    width: 100%;
    max-width: 100%;
}

.input-icon-wrapper .icon {
    opacity: 0.6;
    width: 26px;
    padding: 0px 2px;
}

.input-icon-wrapper .input {
    width: -webkit-fill-available;
}

.k-form .k-form-field .input-icon-wrapper input:focus {
    border-color: transparent;
    box-shadow: none;
}

.k-form .k-form-field .select-wrapper input:focus {
    border-color: transparent;
    box-shadow: none;
}

span.input-label {
    font-size: 12px;
    padding: 10px 0;
    display: inline-block;
    color: #858892;
}

/****** css for inputwithicon input begins ********/

