@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700');

html,
body,
#root,
.App,
.Layout {
  height: 100%;
}

body {
  background-color: #f5f7f9;
}

// ***************************************************************************
// Couldn't get this working in ConnectionList.js with styled-components, putting here
// for popovers
.ant-popover-inner {
  padding: 10px;
  background-color: #262d38;
}

.ant-popover-inner-content {
  background-color: #262d38;
  p {
    &:not(.nohover):hover {
      color: #0079c0;
    }
    padding-left: 10px;
  }
}

.ant-popover-title {
  background-color: #262d38;
  border-bottom: 0;
  color: gray;
}

.ant-popover-arrow {
  border-right-color: #262d38 !important;
  border-bottom-color: #262d38 !important;
}

.ant-popconfirm .ant-popover-inner {
  background-color: #fff;
}

.ant-popconfirm .ant-popover-inner-content {
  background-color: #fff;
}
// ***************************************************************************

.tableau-container {
  min-width: 800px;
}

/*
  LAYOUT
*/

hr {
  margin: 20px 0;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.noData {
  font-size: 1.1em;
  font-weight: bold;
  opacity: 0.2;
  padding: 35px;
  text-align: center;
  margin: auto;
}

.Toastify__toast-container,
.Toastify__toast {
  z-index: 200000;
}

.react-csv-label {
  font-weight: bold;
}

label.k-button.k-secondary {
  border: 1px solid #bfbebe;
}

.k-form fieldset.plain {
  border: none;
  padding: 0;
  margin: 0;

  &:disabled span {
    opacity: 0.5;
  }
}

.required-field > span:first-child::after {
  content: ' *';
  color: rgba(255, 0, 0, 0.65);
}

.k-form .k-form-field .form-group > input.k-textbox.form-group {
  width: 400px;
  float: left;
  background-color: #efefef;
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.2);
  border-radius: 6px 0px 0px 6px;
}

div.form-group {
  height: 36px;
  overflow: hidden;
}

button.k-button.k-primary.form-group {
  width: 39px;
  float: left;
  border-radius: 0px 6px 6px 0px;
  font-size: 26px;
  padding: 0px;
  height: 36px;
}

.k-form .Col .k-grid .k-header a.k-link {
  color: #333333;
  font-size: 11px;
  padding-top: 10px;
  padding-bottom: 2px;
  font-weight: bold;
}

.manager-grid .k-grid-content div {
  max-height: 80px;
  overflow-y: scroll;
}

.manager-grid .k-grid-content div table {
  box-shadow: inset 0px 3px 5px -2px rgba(0, 0, 0, 0.4),
    inset 0px -2px 5px -2px rgba(0, 0, 0, 0.4);
}

.member-grid .k-grid-content div {
  max-height: 150px;
  overflow-y: scroll;
}

.k-grid tr th:first-child,
.k-grid tr td:first-child {
  padding-left: 20px;
}

td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.wrap {
    white-space: normal;
  }
}

body .k-grid th,
body .k-grid td {
  cursor: default;
}

.k-grid-header {
  padding-right: 0 !important;
}

.search-add-bar {
  border-radius: 6px 6px 0px 0px;
  background-color: #ffffff;
  height: 36px;
  padding-top: 2px;
  padding-left: 5px;

  .add-label {
    display: inline-block;
    color: #333333;
    margin-right: 7px;
    padding-top: 6px;
    vertical-align: bottom;
    font-weight: bold;
    font-size: 13px;
    float: right;
  }

  .k-widget.k-autocomplete.k-header {
    width: 200px;
    height: 26px;
    padding: 0px;
    margin-top: 4px;
    display: inline-block;
    border-radius: 7px;
    float: right;
    border: 1px solid #999999;

    input.k-input {
      font-size: 10px;
      width: 200px;
      height: 20px;
      display: inline-block;
    }

    input::placeholder {
      color: #555555;
    }
  }
}

.k-form .k-widget.k-grid.manager-grid {
  margin-bottom: 10px;
}

.k-form .k-form-field.table-section-label {
  margin-bottom: 0px;
  float: left;
}

.member-grid .k-grid-content div table {
  box-shadow: inset 0px 3px 5px -2px rgba(0, 0, 0, 0.4),
    inset 0px -2px 5px -2px rgba(0, 0, 0, 0.4);
}

.member-grid .k-grid-header-wrap,
.manager-grid .k-grid-header-wrap {
  background-color: #dddddd;
}

.member-grid .k-grid-header-wrap th.k-header,
.manager-grid .k-grid-header-wrap th.k-header {
  background-color: transparent;
  opacity: 1;
}

.k-grid-header {
  // border-bottom: 1px solid #999999;
}

.csv-input,
.hide-tab .ant-tabs-nav {
  display: none;
}

.Layout {
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-wrap: wrap;
}

.Content {
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  .ContentTop {
    display: flex;
    justify-content: space-between;
    padding: 18px 20px;
    background-color: white;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);

    h3 {
      margin: 0;
      font-weight: 300;
      letter-spacing: -0.02em;
      font-size: 18px;
    }
  }

  .ContentMain {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}

.Row {
  display: flex;
  margin: 0 -10px;

  .Col {
    padding: 0 10px;
    flex: 1;
    min-width: 0;
  }
}

/*
  TYPOGRAPHY
*/

body {
  font-family: 'Montserrat';
  color: #081433;
  font-size: 14px;
}

h1 {
  font-size: 24px;
  letter-spacing: -0.03em;
  font-weight: 300;
  // margin: 0;
}

h2 {
  font-size: 18px;
  font-weight: 300;
  margin: 0;
}

.TextMuted {
  color: #757779;
}

/*
  TABLES
*/

.TableWrapper {
  position: relative;
  flex: 1;

  .k-grid {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}

.k-grid {
  border: 0;
  color: #081433;
  font-weight: 300;
  background-color: white;

  .k-grid-content {
    background-color: transparent;
    overflow-y: auto;
  }

  .k-grid-table {
    background-color: white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  }

  .k-grid-header-wrap {
    border-right: 0;
  }

  .k-header {
    background-color: #f5f7f9;
  }

  th {
    font-size: 11px;
    opacity: 0.5;
    text-transform: uppercase;
    line-height: 20px;
    font-weight: normal;
  }

  th,
  td {
    padding-top: 10px;
    padding-bottom: 10px;
    border: 0;
    color: #081433 !important;
    cursor: pointer;
    text-overflow: ellipsis;
  }

  th {
    padding-top: 0;
    padding-bottom: 0;
  }
}

label.tag {
  display: inline-block;
  padding: 4px 8px;
  margin-right: 4px;
  margin-top: 2px;
  margin-bottom: 2px;
  border-radius: 50px;
  background-color: #eeeeee;
  white-space: nowrap;

  &:last-child {
    margin-right: 0;
  }

  &.danger {
    background-color: #ffa69d;
  }

  &.warning {
    background-color: #ffd39d;
  }

  &.success {
    background-color: #94c9f4;
  }

  &.info {
    background-color: #94e7f4;
  }

  &.grey {
    background-color: #c7c1e8;
  }
}

.subTitle {
  font-size: 0.8em;
  opacity: 0.8;
}

/*
  FORMS
*/

.k-form {
  padding: 0;
  margin-bottom: 20px;
  color: #081433;
}

.k-form-field {
  max-width: 100%;
}

.k-form .k-form-field input,
.k-form .k-form-field textarea {
  width: 100%;

  &[disabled] {
    background: #e5e7e9;
  }
}

.watermark-info-tooltip {
  position: absolute;
  top: 1px;
  left: 2px;
  z-index: 1;
  opacity: 0.3;
}

@mixin focus-styles {
  border-color: #0079c0;
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.k-form .k-form-field input,
.k-form .k-form-field textarea,
select:not([multiple]),
.k-multiselect .k-multiselect-wrap,
.k-dropdown .k-dropdown-wrap {
  border-radius: 3px;
  box-shadow: none;

  &:focus {
    @include focus-styles;
  }
}

.k-multiselect.k-state-focused .k-multiselect-wrap,
.k-dropdown-wrap.k-state-focused {
  @include focus-styles;
}

.k-multiselect .k-multiselect-wrap li.k-button {
  text-align: left !important;
}

.k-textbox {
  height: 36px;
}

@font-face{
  font-family: text-security-disc;
  src: url("https://raw.githubusercontent.com/noppa/text-security/master/dist/text-security-disc.woff");
}

.k-textbox.lp-connection-secret {
  font-family: text-security-disc;
  -webkit-text-security: disc;
}



.k-animation-container,
.tagify__dropdown {
  z-index: 10003 !important; // set the desired value based on the application requirements
}

.k-list-container {
  .k-item {
    padding: 7px 12px;
    border: 0;
  }
  .k-list {
    .k-custom-item {
      white-space: nowrap;
      overflow: hidden;
    }

    .k-i-plus {
      position: absolute;
      right: 7px;
      background-color: lightgray;
    }
  }
}

select {
  appearance: none;
  -webkit-appearance: none;
  color: #081433;
  border-color: rgba(0, 0, 0, 0.08);
  width: 100%;
  padding: 7px 12px;
  padding-right: 30px;
  background: white;
  border-radius: 2px;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  outline: 0;
  font: inherit;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.4285714286;
  display: inline-flex;
  vertical-align: middle;
  position: relative;
  -webkit-appearance: none;

  option {
    font-weight: 300;
  }

  &:hover,
  &:focus {
    border-color: rgba(0, 0, 0, 0.15);
  }

  &:focus {
    box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.06);
  }
}

select:not([multiple]) {
  background: #fff
    url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
    no-repeat right 0.75rem center;
  background-size: 8px 10px;
}

select:disabled {
  color: #555555;
  background: none !important;
  background-color: white !important;
  cursor: not-allowed;
  box-shadow: none !important;
  border: 0px solid white;
}

select:disabled:hover {
  border-color: #dddddd;
}

.form-error {
  color: rgb(215, 81, 24);
  margin-top: 5px;
}

.k-form .k-form-field:last-child,
.k-form-inline .k-form-field:last-child {
  margin-bottom: 12px;
}

.k-form .checkbox-wrapper input[type='checkbox'] {
  display: inline-block;
  margin-right: 10px;
}

label.k-form-field {
  margin-top: 0px !important;
  margin-left: 0px !important;
}

/*
  BUTTONS & LINKS
*/

a {
  color: #0079c0;
  text-decoration: none;

  &:hover {
    color: darken(#0079c0, 10%);
  }
}

/*
  BUTTONS
*/
button {
  cursor: pointer;
}

.k-button {
  border-color: transparent;
  height: 35px;
  border-color: rgba(0, 0, 0, 0.08);
  color: #f6f6f6;
  background-color: #f6f6f6;
  background-image: linear-gradient(#f6f6f6, #f1f1f1);
  background-clip: padding-box;
  border-radius: 2px;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  font-size: 14px;
  line-height: 1.42857;
  font-family: inherit;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  padding: 7px 14px;
}

.k-button.k-none {
  height: 100%;
}

.k-button:disabled {
  outline: none;
  cursor: default;
  opacity: 0.6;
  -webkit-filter: grayscale(0.1);
  filter: grayscale(0.1);
  pointer-events: none;
  box-shadow: none;
}

.k-button.k-primary {
  color: #f6f6f6;
  background-color: #0079c0;
  border-color: #0079c0;
  transition: all 0.3s;
  background-image: none;
  box-shadow: none !important;
  border-radius: 6px;

  &:hover {
    background-color: #0059a0;
    border-color: #0059a0;
  }

  &:active {
    background-color: #003980;
    border-color: #003980;
  }

  &.k-bare {
    color: #0079c0;

    &:hover {
      color: #0059a0;
    }
  }
}

.k-button.k-success {
  background-color: #6eb733;
  color: white !important;
  border-color: #6eb733;
  transition: all 0.3s;
  background-image: none;
  box-shadow: none !important;

  &:hover {
    background-color: #4e9713;
    border-color: #4e9713;
  }

  &:active {
    background-color: #2e7700;
    border-color: #2e7700;
  }

  &.k-bare {
    color: #2e7700;

    &:hover {
      color: #0e5700;
    }
  }
}

.k-button.k-danger {
  background-color: #d75353;
  color: white !important;
  border-color: #d75353;
  transition: all 0.3s;
  background-image: none;
  box-shadow: none !important;

  &:hover {
    background-color: #b73333;
    border-color: #b73333;
  }

  &:active {
    background-color: #971313;
    border-color: #971313;
  }

  &.k-bare {
    color: #d75353 !important;

    &:hover {
      color: #971313 !important;
    }
  }
}

.k-button.k-cancel {
  background: #ffffff;
  border-color: #0079c0;
  color: #0079c0;

  &:hover {
    background-color: darken(#ffffff, 10%);
    border-color: darken(#0079c0, 10%);
  }
}

/*
  MODALS
*/

.k-dialog-wrapper {
  overflow-x: hidden;
  overflow-y: auto;
  justify-content: flex-start;
}

.k-dialog {
  margin: 50px 0;
  border-radius: 3px;

  .k-dialog-titlebar {
    background: #f5f7f9;
    color: #081433;
    padding: 15px 20px;
    border-radius: 3px 3px 0 0;

    .k-dialog-close {
      opacity: 0.5;
    }
  }

  .k-dialog-content {
    padding: 20px;

    hr {
      margin-left: -20px;
      margin-right: -20px;
    }
  }
}

/*
  TABS
*/

.k-tabstrip-top > .k-tabstrip-items {
  margin-bottom: 15px;
}

.k-tabstrip-items .k-animation-container-relative {
  display: block !important;
}

.k-tabstrip-items .k-item {
  color: #0079c0;
  border: 0;
  border-bottom: 1px solid transparent;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  font-size: 13px;
  margin-right: 20px;

  &:hover {
    color: darken(#0079c0, 15%);
  }
}

.k-tabstrip-items .k-link {
  padding: 10px 0;
}

.k-tabstrip > .k-content.k-state-active {
  overflow: visible;
}

.k-tabstrip-top > .k-tabstrip-items .k-item.k-state-active {
  color: #081433;
  border-bottom-color: #081433;
  background-color: transparent;
}

.k-tabstrip > .k-content {
  border: 0;
  padding: 0;
  background-color: transparent;
}

.table-tab {
  flex-direction: column;

  &.k-state-active {
    display: flex !important;
  }

  > div {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}

/*
  POPUP
*/

.k-popup {
  border: 0;

  .k-button {
    justify-content: flex-start;
  }
}

.k-grid-header,
.k-grid-toolbar,
.k-grouping-header,
.k-grid-add-row,
.k-grid-footer {
  color: #656565;
  background-color: #f1f3f6;
}

/*
  TOOLTIP
*/

.k-tooltip {
  .k-tooltip-content {
    max-width: 240px;
    font-size: 12px;
    word-wrap: break-word;
  }
}

.fpo {
  opacity: 0.6;

  &::before {
    content: 'FPO';
    z-index: 5;
    position: absolute;
    font-size: 5em;
    color: #ff7c7c66;
    height: 100%;
    width: 100%;
    font-weight: bold;
    margin-top: 10%;
    text-align: center;
    transform: rotate(-45deg);
  }
}

.ant-table-thead {
  font-size: 11px;
}
.ant-tooltip .isoMenuHolder svg {
  display: none;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.react-joyride__beacon:focus {
  outline: none;
}

/* antd buttons css*/

button.ant-btn.n-button.n-cancel.ant-btn-default {
  border: 3px solid;
  font-weight: 600;
  color: #677791;
  min-width: 89px;
  box-sizing: border-box;
  border-radius: 8px;
}

button.ant-btn.n-button.n-cancel.ant-btn-default:hover {
  border: 3px solid #677791;
  font-weight: 600;
  background-color: #677791;
  color: #fff;
  min-width: 89px;
  box-sizing: border-box;
}

button.ant-btn.n-button {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 89px;
}

button.ant-btn.n-button.accounts_product_submit_btn {
  margin: 0 !important;
}

button.ant-btn.n-button.accounts_product_remove{
  background-color: red;
  border: none
}
button.ant-btn.n-button.accounts_product_remove:hover{
  background-color: rgb(171, 0, 0);
}

.ant-btn-primary {
  background-color: #0079c0;
  border-color: #0079c0;
  border-radius: 8px;
}

.ant-btn-default {
  border-radius: 8px;
}

.button-icon img.icon {
  width: 23px;
  margin-right: 10px;
}

span.button-text {
  font-size: 15px;
  font-weight: 600;
}

.k-widget.k-grid {
  top: 10px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #f0f1f4;
  border: transparent;
  border-radius: 5px;
}

textarea.k-textbox.n-textarea {
  border: none;
  outline: none;
  box-shadow: none;
}

textarea.k-textbox.n-textarea:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.ant-skeleton {
  padding: 19px 32px 0 32px;
}

.user_form_roles_select {
  .ant-select-item-option:hover{
    background-color: #f0f1f4a5;
  }
}

.menu_popover .ant-popover-content .ant-popover-arrow {
  display: none;
}

.menu_popover .ant-popover-content .ant-popover-inner {
  background-color: #262d38;
  width: 200px;
}

.ant-popover.menu_popover {
  padding-top: 0;
}

.action__svg path {
  stroke: #8b8e94;
}

.cp-edit__svg path {
  stroke-width: 2px;
}

.action__svg_del path {
  fill: #8b8e94;
}

.action__svg_del_w path {
  fill: #fff;
}

.select_opt_icon__svg path {
  stroke: #8e9196;
}

.select_opt_icon__svg {
  width: 25px;
  margin-right: 5px;
}

.remove__svg path {
  stroke: #8b8e94;
}

.x_calls_svg path {
  stroke: #414242;
}

.x_calls_svg {
  width: 25px;
  height: 25px;
  margin-right: 46px;
  cursor: pointer;
}

.remove__svg {
  width: 25px;
  cursor: pointer;
}

.play__svg {
  width: 25px;
}

.play__svg path {
  stroke: #fff;
}

.md__svg {
  width: 20px;
}

.callSearch__svg {
  width: 17px;
  margin-right: 10px;
}

.callSearch__svg path {
  stroke: #fff;
}

.plus_circle__svg0 path {
  stroke: #339eec;
}
.plus_circle__svg1 path {
  stroke: #d28f3b;
}
.plus_circle__svg2 path {
  stroke: #cd4b32;
}
.plus_circle__svg3 path {
  stroke: #6b9d7f;
}
.plus_circle__svg4 path {
  stroke: #895385;
}
.plus_circle__svg5 path {
  stroke: #d8cbc1;
}

.play_media__svg {
  width: 25px;
  margin-left: -5px;
}

.play_media__svg path {
  stroke: #76849c;
}

.vbaccess__svg {
  width: 25px;
}

.vbaccess__svg path {
  stroke: rgb(182, 85, 169);
}

.download_JSON__svg {
  width: 20px;
  height: 20px;
}

.download_JSON__svg path {
  stroke: #7a7878;
}

.revert__svg {
  width: 25px;
  height: 25px;
}

.revert__svg path {
  stroke: grey;
}

.download__svg path {
  stroke: #fff;
}

.upload__svg {
  margin-right: 10px;
}

.check__svg path {
  stroke: #262d38;
}

.check__svg {
  width: 25px;
  height: 18px;
}

.grey__svg path {
  stroke: #677791;
}

.blue__svg path {
  stroke: #339eec;
}

.oncall__svg path {
  stroke: #339eec;
}

.oncall__svg {
  width: 17px;
  height: 17px;
  margin-right: 2px;
}

.offcall__svg path {
  stroke: grey;
}

.offcall__svg {
  width: 17px;
  height: 17px;
  margin-right: 2px;
}

.ant-card-body {
  padding: 16px 32px;
}

.ant-card-body hr {
  margin: 8px 0px;
}

.ant-row.vb_row {
  padding: 0px !important;
}

.mb20 {
  margin-bottom: 20px;
}

.flex_row_center {
  display: flex;
  align-items: center;
}

.detail_text {
  margin: 0 0 0 10px !important;
  color: #262d38;
  font-weight: 400;
  font-size: 13px;
}

.icon_img {
  width: 20px;
  height: 20px;
}

.ant-table-wrapper {
  width: 100%;
}

.ant-table {
  border-radius: 4px;
}

.ant-modal-content {
  border-radius: 8px;
}

.ant-modal-header {
  border-radius: 8px 8px 0 0;
}

.ace_layer.ace_gutter-layer.ace_folding-enabled {
  background: #fff;
}

div#queryEditor {
  width: 97% !important;
}

// for firefox start
.input-icon-wrapper .input {
  width: 100%;
}
// for firefox end

.ant-picker {
  background: #f0f1f4;
}

.ant-row.date-row {
  padding: 0 0 16px 0;
}

.adjust-width.ant-col-12 {
  max-width: 48% !important;
}

//******************** START css for new antd toast under utils/toast.js //
.toast-info-container .ant-message-notice-content {
  color: #b6c0cc !important;
  background: #3d3d3d !important;
  min-height: 35px !important;
}

.toast-error-container .ant-message-notice-content {
  color: #fff !important;
  background: #f56868 !important;
  min-height: 35px !important;
}

.ant-message .anticon {
  color: #b6c0cc;
}

.ant-message-custom-content.ant-message-info {
  display: flex;
}

.ant-message-custom-content.ant-message-error {
  display: flex;
}
//******************** END css for new antd toast under utils/toast.js //

.bg-none:hover {
  background-color: transparent !important;
}

.pointer {
  cursor: pointer;
}

.scroll-content-modal {
  overflow-x: hidden;
  max-height: 50vh;
  padding-right: 5px;
  min-height: 23vh;
}

select.k-textbox {
  padding: 0 0 0 5px;
}

.pd-10 {
  padding-top: 8px;
}

.ml0 {
  margin-left: 0px !important;
}

.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle {
  position: absolute;
  right: 0;
}

.dark-input {
  background-color: #f4f4f4;
}

.dark-input:focus {
  background-color: #f4f4f4;
}

.itemTitle {
  height: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 10px;
  width: 100%;
}

textarea.k-textbox.dark-input {
  border: none;
  background-color: #f0f1f4;
}

.listplus__svg path {
  stroke: #677791;
}

.listplus__svg_hl path {
  stroke: #1890ff;
}

.user__svg path {
  stroke: #677791;
}

.user__svg {
  width: 16px;
  margin-right: 5px;
}

.envelope__svg path {
  stroke: #8b8e94;
}

.waroct__svg path {
  stroke: #677791;
}

.chatCenteredText__svg path {
  stroke: #677791;
}

.waroct__svg_hl path {
  stroke: #1890ff;
}

.dots__svg path {
  stroke: #7e7f82;
  fill: #7e7f82;
}

.smiley__svg path {
  stroke: #7e7f82;
}

.smiley__svg {
  cursor: pointer;
}

.mdark-input {
  background-color: rgba(103, 119, 145, 0.1);
  border-radius: 4px;
}

.pd-32 .ant-card-body {
  padding: 16px 32px;
}

.ant-select.custom_ant_select.ant-select-single.ant-select-show-arrow {
  width: 100%;
  background-color: #f0f1f4;
  height: 35px;
  border-radius: 4px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 35px;
}

.input_label {
  font-size: 12px;
  padding: 10px 0;
  display: inline-block;
  color: #858892;
}

.custom_dropdown .ant-select-item-option {
  color: #858892;
}

.custom_dropdown .ant-select-item-option:hover {
  background-color: #ffffff10;
  .ant-select-item-option-content {
    color: #fff;
  }
}

.custom_dropdown .ant-select-item-option-selected {
  color: #262d38;
}

.custom_search .ant-input {
  background-color: #00000050;
  color: white;
}

button.rst__expandButton {
  top: 27px;
}

.ant-select-dropdown.ant-select-dropdown-placement-topLeft {
  background-color: #262d38;
}

.ant-select-dropdown.custom_ant_select-2 {
  background-color: #262d38;
}

.ant-select-item-option-content {
  color: #8e9196;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  line-height: 25px;
}

.select-type-1 .ant-select-item.ant-select-item-option:hover {
  background-color: #f0f1f4;
}


span.ant-select-selection-item {
  display: flex;
  align-items: center;
  color: #8e9196;
  font-weight: 500;
  font-size: 14px;
}

.category-library-filter-container span.ant-select-selection-item {
  display: block;
}

.rst__rowContents {
  width: 120% !important;
}

.cl-items .ant-tabs-nav {
  margin-left: 20px;
}

.cl-items .ant-tabs-tab {
  font-size: 12px;
  font-weight: 600;
  color: #677791;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: transparent;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #ffffff10;
}

.usage_filter_select .ant-select-item-option:hover{
  background-color: #f7f7f7 !important;
}

.usage_filter_select .ant-select-item-option-selected {
  background-color: #f0f0f0 !important;
}

.campaign_select .ant-select-selector {
  background-color: transparent !important;
  border-radius: 5px;
}

.form-error {
  font-size: 12px;
}

.input-desc {
  font-size: 9px;
}

.svg-pr-blue path {
  stroke: #0079c0;
}

.ant-picker-range-wrapper.ant-picker-date-range-wrapper {
  margin-left: -307px;
}

.ant-picker.ant-picker-range {
  border: none;
  border-radius: 4px;
}

.datePop .ant-picker-input > input {
  color: #fff;
}

.ant-picker-input > input {
  color: #656565;
}

.ant-picker-dropdown {
  z-index: 999999;
}

.datePop .ant-popover-inner-content {
  padding: 0px 0px;
}

.datePop .ant-popover-title {
  padding: 0;
  font-size: 13px;
  color: #7e8288;
}

.hr_border {
  opacity: 0.5;
  border: 1px solid #677791;
}

.chart-pop-container .ant-popover-arrow,
.datePop .ant-popover-arrow {
  display: none;
}

label.ant-radio-wrapper {
  color: #262d3880 !important;
  // opacity: 0.5;
}

.x__svg_icon {
  position: absolute;
  margin-left: 14px;
  margin-top: 5px;
}

.icon__svg path {
  stroke: #656565;
}

.lence__svg path {
  stroke: #656565;
}

.lence__svg {
  width: 16px;
  height: 16px;
}

.icon__svg {
  height: 18px;
  width: 18px;
  margin-right: 8px;
}

.lock__svg path {
  stroke: #656565;
}

.topbar-rht-op div:hover .lock__svg path {
  stroke: #1890ff;
}

.arrow__svg path {
  stroke: #1890ff;
}

.small_blue__svg path {
  stroke: #1890ff;
}

.small_orange__svg path {
  stroke: #d28f3b;
}

.small_blue__svg {
  width: 22px;
  height: 22px;
}

.small_orange__svg {
  width: 22px;
  height: 22px;
}

.arrow__svg {
  height: 15px;
  width: 15px;
}

.lock__svg {
  height: 18px;
  width: 18px;
  float: right;
}

.focus-visible {
  box-shadow: none !important;
}

.ace_hidden-cursors {
  opacity: 0;
}

.ant-select {
  span.ant-select-selection-placeholder {
    color: #656565;
  }

  &.ant-select-disabled span.ant-select-selection-placeholder {
    color: lightgray;
  }
}

.ant-select-item-empty {
  height: 200px;
}

.ant-select-item-empty:first-of-type {
  align-items: center;
  height: 200px;
  display: flex;
  color: grey;
}

input.ant-input.ant-input-borderless {
  color: #fff;
}

.switch-accounts-wrap input.ant-input.ant-input-borderless {
  background: #262D38;
}

.callsearch-bookmark-wrap input.ant-input.ant-input-borderless {
  background: #262D38;
}

.animateRight:hover .animateIcon {
  transition: ease-in-out 0.1s;
  transform: translateX(4px);
}

.animateRight .animateIcon {
  margin-left: 0px;
}

.ant-select-dropdown.ant-select-dropdown-placement-bottomLeft {
  padding: 0;
  z-index: 999999;
}

.year_select .ant-select-selector {
  background-color: #515760 !important;
}

.year_select .ant-select-selection-item {
  color: #fff;
}

.mt-10 {
  margin-top: 10px;
}

button.ant-btn.ant-btn-primary.n-button.sg-button.sg-primary {
  color: #fff;
}

.ant-card-head {
  border-bottom: none;
}

.ant-menu-inline-collapsed {
  &-tooltip {
    svg {
      display: none;
    }
  }
}

.ant-card {
  border-radius: 4px;
}

.predictive-tbl-cl {
  padding: 0 !important;
}

.predictive-tbl-cl .ant-table-content {
  padding: 0 !important;
}

.custom_dropdown span.ant-input-affix-wrapper.custom_search {
  background-color: #252222 !important;
}

.custom_dropdown .ant-input-affix-wrapper {
  border: none;
}

.metadata-drop-cl .ant-select-item-empty div {
  display: none !important;
}

.mb10 {
  margin-bottom: 15px;
}

.hover:hover .ant-anchor-link-title span {
  color: #0079c0;
}

.topbar-rht-op div:hover > span {
  color: #0079c0 !important;
}

.center {
  display: flex;
  // justify-content: center;
  align-items: center;
}

.pointer {
  cursor: pointer;
}

.x__svg path {
  stroke: rgba(0, 0, 0, 0.5);
}

.x__svg {
  float: right;
}

.mr-5 {
  margin-right: 5px;
}

.vb-input {
  border: none;
  box-shadow: none;
  background-color: #f0f1f4;
  color: black;
  padding-left: 5px;
}

.blue-btn {
  background-color: #0079c0;
  background-image: none;
  border-radius: 4px;
}

textarea.k-textbox.dark-input:focus-visible {
  outline: none;
}

.k-form .k-form-field .input-icon-wrapper input {
  padding: 0 0 0 10px;
}

input.k-textbox.vb-input {
  padding: 0 0 0 10px;
}

.interactionsTable button.k-button {
  color: #000
}

.actionIcon path {
  stroke: rgb(103, 119, 145)
}

.actionIcon {
  width: 20px;
  height: 20px;
  margin-right: 12px;
  cursor: pointer;
}

.actionIcon:hover path {
  stroke:#1890ff;
}

.dup__svg:hover {
  stroke: #fff;
}

.workbench-query .ant-table-wrapper {
  padding: 0px !important;
}

.textDark {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  color: #262D38;
}

.textLight {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  color: #262D38;
  opacity: 0.5;
}

.scroll-y {
  overflow-y: auto;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 4px;
}

input.ant-input-number-input {
  background-color: #e1e7ec;
  border: none;
  border-radius: 4px;
}

.ant-input-number {
  border: none;
}

.ant-input-number-input {
  height: 35px;
}

.list_active path {
  stroke: #339EEC;
}

.list_inactive path {
  stroke: #677791;
}
.ant-popover {
  z-index: 999999 !important;
}

.ant-tooltip {
  z-index: 9999999;
}

.interactionsTable .ant-table-thead > tr > th {
  background: #fff;
}

.interaction_results_list_num_calls_text{
  display: flex;
  align-items: end;
}

.no-data-text-lg {
  font-size: 14px !important;
  font-weight: 500 !important;
  color:#677791 !important;
}



.vis-option:hover {
  background-color: #000;
}

.bottom{
  .ant-menu-title-content {
    a{
      color: rgba(0,0,0,.85)!important;
      display: flex;
      align-items: center;
    }
    display: flex;
    align-items: center;
  };
  .anticon-down{
    margin-right: 10px;
  }
}

.top{
  .ant-menu-submenu-inline{
    .ant-menu-title-content{

      margin-left: 0!important;
    }
  }
}

.ant-menu-item{
  .ant-menu-title-content{
    a{
      color: rgba(0,0,0,.85)!important;
      display: flex;
      align-items: center;
    }
  }
}

.ant-menu-inline-collapsed {
  .ant-menu-title-content{
    display: block!important;
    margin: -6px;
  }
}


.ant-table-column-title {
  flex: none;
}

.ant-table-column-sorters {
  justify-content: start;
}

.google-visualization-tooltip {
  width: auto;
  min-width: 260px;
  border-radius: 8px !important;
  border: 3px solid lightgray !important;
  padding: 10px !important;
  height: auto !important;
 }

 .tooltip-label-gc {
   font-weight: 400;
   font-size: 15px;
   color: rgb(101, 101, 101);
 }

 .tooltip-val-gc {
  font-weight: 600;
  font-size: 15px;
  color: #262D38;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  line-height: 22px;
  letter-spacing: 0px;
}

.gc-tooltip-wrap {
  display: flex;
  justify-content: space-between;
}

.interaction-drawer .ant-drawer-header-title {
  display: flex;
  justify-content: flex-end;
}

li.ant-menu-item:hover {
  background-color: #f0f1f4;
}

.ant-select-item.ant-select-item-option.bulk-sel-option:hover {
  background-color: #ffffff10;
}

.vb-dash-edit-sidebar hr {
  margin: 0px 0;
}
button.ant-btn.n-button.n-cancel {
  border-radius: 8px;
  border: 3px solid grey;
}

button.ant-btn.n-button.n-cancel:hover {
  border-radius: 8px;
  border: 3px solid #40a9ff;
}

.ant-menu-inline-collapsed > .ant-menu-item {
  padding: 0 calc(50% - 8px)!important;
}

.highlightedText {
  background-color: #677791;
  padding: 8px;
  color: #fff;
  border-radius: 4px;
}

.ant-select-dropdown.custom_ant_select.ant-select-dropdown-placement-bottomLeft {
  background-color: #262d38;
}

.count-input .form-error {
  width: 300px;
}

.call-length-inputs input.ant-input {
  background-color: #e1e6ec;
}

.mapping-tab {
  border: navajowhite;
  height: 40px;
  margin-left: 20px;
  color: rgb(97, 97, 97);
  font-weight: 600;
  padding: 10px;
  display: flex;
  align-items: center;
  border-radius: 4px;
}

button.mapping-tab.active {
  background-color: #3394cd;
  color: #fff;
  border-radius: 4px;
}

.real-time-connection-status{
  padding-right: 64px;
}

.real-time-connection-status-container{
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: end;
}
//treeselect css start
.ant-select-tree-treenode {
  color: #8e9196 !important;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  line-height: 25px;
  padding: 4px 0px !important;
}

.ant-select-tree-treenode-selected {
  color: #fff !important;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
    line-height: 25px;
    background: #8e9196 !important;
    padding: 4px 0px !important;
}

.ant-select-tree-list {
  background-color: #262d38 !important;
}

.ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: transparent;
}

.ant-select-tree .ant-select-tree-node-content-wrapper:hover {
  background-color: #ffffff10;
}

.ant-select-tree-treenode.ant-select-tree-treenode-switcher-close:hover {
  background: #8e9196;
  color: #fff !important;
}

.category-filter-tree-select .ant-select-tree-title {
  color: #fff;
}
//treeselect css ends

.category-filter-tree-select .ant-select-tree-treenode:hover{
  color: white !important;
}

.vb-prim:focus {
  color: #fff;
  background-color: #0079c0 !important;
  border-color: #0079c0 !important;
}

.vb-prim:hover {
  color: #fff;
  border-color: #40a9ff;
  background: #40a9ff;
}

.d-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dark-select .rc-virtual-list {
  background-color: #343a44 !important;

}

.dark-select .ant-select-item-option-content {
  color: #fff !important;
}

.call-search-sidebar span.ant-select-selection-placeholder {
  font-weight: 500;
  color: #8e9196;
}

.speaker_select.ant-select-dropdown {
  padding: 0px;
}

.scroll-content {
  overflow-x: hidden;
  max-height: 45vh;
  padding-right: 5px;
  min-height: 23vh;
}

.jobs-queue-type-popover {
  width: 500px;
  p {
    color: white;
    font-size: 13px;
    line-height: 22px;
    width: 500px;
  }
}

.messagingicon_play path {
  stroke: #1e1d20!important;
}

.status_filter_download{
  .ant-select-selection-placeholder{
    color: #c7c3c1 !important;
  }
}